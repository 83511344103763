@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

.active {
  color: #2563EB !important;
}

/* 3D Card design */
.card-3d {
  color: #101832;
  cursor: pointer;
  display: inline-block;
  position: relative;
  border: 3px #00bcdd solid;
  border-image: linear-gradient(45deg, #00bcdd 0%, #ff00ff 100%);
  border-image-slice: 1 1 1 1;
  z-index: 1;
  box-shadow: -0.5em 0.5em rgba(16, 24, 50, 0);
  transform-origin: left bottom;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.card-3d:before {
  content: '';
  position: absolute;
  top: 0px;
  left: -8px;
  width: 5px;
  height: 102%;
  background-image: linear-gradient(#9B4AF2, #00bcdd);
  transform: skewY(-45deg);
}

.card-3d:after {
  content: '';
  position: absolute;
  bottom: -9px;
  right: -0px;
  height: 7px;
  width: 102%;
  background-image: linear-gradient(to right, #00bcdd, #6472EA);
  transform: skewX(-45deg);
}

.card-3d:hover {
  background-color: white;
  background-size: 100%;
  transform: translate(0.5em, -0.5em);
  box-shadow: -1em 1em 0.15em rgba(16, 24, 50, 0.1);
}

@media (max-width: 1279px) {
  .card-3d:after {
    width: 101%;
  }
}

/* Flip Card */
.flip-card {
  width: 100%;
  height: 100%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 335px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* .flip-card-front {
  background-color: #bbb;
  color: black;
} */

.flip-card-back {
  transform: rotateY(180deg);
}